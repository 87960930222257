.progress-bar-container {
  width: 290px;
  height: 5px;
  background-color: #00718f;
  border-radius: 4px;
  padding-right: 2px;
  padding-left: 2px;
  margin-right: 5px;
  margin-left: 5px;
}

input[type="range"] {
  appearance: none;
  width: 243px;
  height: 6px;
  border-radius: 3px;
  background: var(--Dark, #00718f);
  outline: none;
  cursor: pointer;
  accent-color: white;
}

input[type="range"]::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #fff; /* Set the color to white */
  box-shadow: 0 0 3px #000; /* Add a subtle shadow if desired */
}
