.status-pending {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  padding: 6px;
  gap: 8px;
  border-radius: 8px;
  background: var(--Yellow, #ffb302);
  border: 1px solid var(--Primary-Color, #ffb302);
  height: 24px;
  color: white;
  text-transform: capitalize;
}

.status-active {
  display: flex;
  padding: 5px;
  width: 90px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-Color, #18c07a);
  background: var(--Primary-Color, #18c07a);
  color: white;
  text-transform: capitalize;
}

.status-inactive {
  display: flex;
  padding: 6px;
  width: 90px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-Color, #c40a0a);
  background: var(--Primary-Color, #c40a0a);
  color: white;
  text-transform: capitalize;
}

.status-rejected {
  display: flex;
  padding: 6px;
  width: 95px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-Color, #c40a0a);
  background: var(--Primary-Color, #c40a0a);
  color: white;
  text-transform: capitalize;
  height: 24px;
}

.location-dropdowns {
  border: "4px solid green !important";
}
.border-none {
  border: none !important;
  outline: none !important;
  cursor: pointer;
}
.credit-price-value {
  color: var(--Text, #1a1d1f);
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-left: 5px;
}

input[type="checkbox"] {
  accent-color: #0a9cc4;
}
.button-approved {
  width: 85px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #0fb957;
  background-color: #0fb957;
  color: white;
  margin-right: 10px;
  font-size: 14px;
}
.button-rejected {
  width: 85px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid var(--Primary-Color, #f65353);
  background: var(--Primary-Color, #f65353);
  color: white;
  font-size: 14px;
}
/* .react-tel-input .form-control {
  width: 100% !important;
} */

.PhoneInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 11px !important;
  border: 0.0625rem solid #d2d6da;
  padding: 8px 12px;
  font-size: 0.95rem;

  .PhoneInputInput {
    border: none;
  }
}
input:focus-visible {
  outline-width: 0;
}
.error-phone-number {
  border: 1px solid#c40a0a !important;
}
