/* src/components/CardSetupForm.css */

.card-setup-form {
    width: 100%;
    margin: auto;
}

.card-setup-label {
    display: block;
    margin-bottom: 10px;
}

.card-setup-input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 16px;
}

.card-setup-element {
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 16px;
}

.card-setup-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.card-setup-button:hover {
    background-color: #45a049;
}