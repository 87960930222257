* ----------------- plans styles start---------------- *
.plans-list-grid {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr)); */
    grid-template-columns: 1fr 1fr 1fr;

    gap: 1rem;
    padding: 0 10%;
}

.plans-list-grid-item {
    background-color: white;
    padding: 2.5rem 1rem;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(131, 146, 171, 0.2);
}

.plan-card {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
}

.plan-card .buy-button {
    width: 80%;
}

@media only screen and (max-width: 1450px) {
    .plans-list-grid {
        padding: 0 0;
    }
}

@media only screen and (max-width: 900px) {
    .plans-list-grid {
        grid-template-columns: 1fr 1fr;
        padding: 0 5%;
    }
}

@media only screen and (max-width: 750px) {
    .plans-list-grid {
        padding: 0 0;
    }
}

@media only screen and (max-width: 600px) {
    .plans-list-grid {
        grid-template-columns: 1fr;

        padding: 0 8%;
    }
}

@media only screen and (max-width: 500px) {
    .plans-list-grid {
        padding: 0 0%;
    }
}