.stepper-container {
  background-color: white;
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 1.5rem;
  border-radius: 20px;
  box-shadow: 0px 5px 14px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.stepper-container .dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 3px solid #ced4da;
  stroke: #ced4da;
  stroke-width: 3px;
  z-index: 2;
}

.stepper-container .filled {
  background-color: #172b4d;
  border: 1px;
}

.stepper-container .connector {
  width: 50px;
  height: 1px;
  background-color: #172b4d;
}

.stepper-container .stepper-labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepper-labels .first-item {
  padding-right: 1rem;
}

.stepper-labels .middle-item {
  padding-left: 4rem;
}

.stepper-labels .last-item {
  padding-left: 1rem;
}
