.PhoneInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 11px !important;
  border: 0.0625rem solid #d2d6da;
  padding: 8px 12px;
  font-size: 0.95rem;

  .PhoneInputInput {
    border: none;
  }
}
input:focus-visible {
  outline-width: 0;
}
.error-phone-number {
  border: 1px solid#c40a0a !important;
}
