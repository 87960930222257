.current-frequency-value {
  color: var(--Text, #1a1d1f);
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-left: 5px; /* Adjust the value as needed */
}
.frequency-value-session {
  color: var(--Text, #1a1d1f);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 5px;
}

.parent {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.button-size {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1750px) {
  .parent {
    flex-direction: column !important;
    align-items: center !important;
    gap: 16px;
    padding-bottom: 20px !important;
  }
  .button-size {
    flex-direction: column;
    gap: 15px;
    display: flex;
    align-items: center;
  }
}

.PhoneInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 11px !important;
  border: 0.0625rem solid #d2d6da;
  padding: 8px 12px;
  font-size: 0.95rem;

  .PhoneInputInput {
    border: none;
  }
}
input:focus-visible {
  outline-width: 0;
}
.error-phone-number {
  border: 1px solid#c40a0a !important;
}
