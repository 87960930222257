.current-frequency-value {
  color: var(--Text, #1a1d1f);
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-left: 5px; /* Adjust the value as needed */
}
.frequency-value-session {
  color: var(--Text, #1a1d1f);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 5px;
}
