.status-pending {
  display: flex;
  height: 24px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: var(--Yellow, #ffb302);
  color: white;
  text-transform: capitalize;
  width: 95px;
}

.status-active {
  display: flex;
  height: 24px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-Color, #18c07a);
  background: var(--Primary-Color, #18c07a);
  color: white;
  text-transform: capitalize;
  width: 95px;
}

.status-inactive {
  display: flex;
  height: 24px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-Color, #c40a0a);
  background: var(--Primary-Color, #c40a0a);
  color: white;
  text-transform: capitalize;
  width: 95px;
}

.role {
  display: flex;
  height: 24px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Dark, #00718f);
  background: rgba(10, 156, 196, 0.2);
  text-transform: capitalize;
  font-weight: 600;
  color: var(--Primary-Color, #0a9cc4);
  width: 130px;
}

.PhoneInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 11px !important;
  border: 0.0625rem solid #d2d6da;
  padding: 8px 12px;
  font-size: 0.95rem;

  .PhoneInputInput {
    border: none;
  }
}
input:focus-visible {
  outline-width: 0;
}
.error-phone-number {
  border: 1px solid#c40a0a !important;
}
