#initial-frquency-slider {
  position: relative;
}

#zooming-frquency-slider {
  position: relative;
  margin-top: 10.8rem;
}
#fine-tuning-frquency-slider {
  position: relative;
  margin-top: 10.8rem;
}

#final-test-frquency-slider {
  position: relative;
  margin-top: 2.2rem;
}

.slider-header-title {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffff;
  padding: 0.4rem 2rem;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;
}

.vertical-bar {
  width: 1px;
  height: 180%;
  background-color: black;
  position: absolute;
  left: 20%;
  bottom: -140%;
}

.vertical-sub-bar {
  width: 1px;
  height: 70%;
  background-color: black;
  position: absolute;
  left: 20%;
  top: 77%;
}

.marks-label {
  color: black;
  font-size: 0.9rem;
  position: absolute;
  bottom: -200%;
}
