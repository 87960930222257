.rc-slider-dot {
  display: none !important;
}

.rc-slider-mark {
  pointer-events: none !important;
}

.rc-slider-disabled {
  background-color: transparent !important;
}

.frequency-timer {
  position: absolute;
  top: 43%;
  left: 57%;
}

.final-test-slider {
  position: relative;
  pointer-events: none !important;
}
