.status-complete {
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-Color, #18c07a);
  background: var(--Primary-Color, #18c07a);
  color: white;
  text-transform: capitalize;
}

.status-incomplete {
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Primary-Color, #c40a0a);
  background: var(--Primary-Color, #c40a0a);
  color: white;
  text-transform: capitalize;
}
