::placeholder {
  color: "var(--wireframes-primary, var(--Body, #373D3F))";
  font-family: "Open Sans";
  font-size: 14;
  font-style: "normal";
  font-weight: 400;
  line-height: "22px";
  opacity: 1;
}
@import "rsuite/dist/rsuite.css";
/* Style for the table container */
.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Style for the table */
table {
  border-collapse: collapse;
  width: 100%; /* Full width */
}

/* Style for the last column cells */
/* Style for the last column cells within .fixed-col */
.fixed-col table td:last-child {
  position: sticky; /* Make the last column sticky */
  right: 0; /* Stick it to the right side */
  background-color: #f2f8fe !important; /* Background color */
  /* margin-left: -20px !important; */
}

/* Style for the last column header cells within .fixed-col */
.fixed-col table th:last-child {
  position: sticky;
  right: 0; /* Stick it to the right side */
  background-color: #ffff !important; /* Background color */
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

.clickable:hover {
  background-color: #f5f5f5;
}

@media screen and (max-width: 1300px) {
  .custom-container {
    max-height: 50vh;
  }
}
